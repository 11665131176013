import React, { useEffect, useState } from "react"
import RuleSwiperItemsList from "../../ContentBuilder/SwiperList/RuleSwiperItemsList"
import config from "../../../config/config"
import axios from "axios"

const HomeDesignRelatedItems = ({ id, vid }) => {
  const [allData, setAllData] = useState([])
  useEffect(() => {
    if (id) {
      var _allData = []
      var _data = {
        id: id,
        vid: vid,
        type: "HomeDesign",
      }
      axios
        .post(config.host + "/tribecaapi/getrule", _data)
        .then(response => {
          let allRelatedNodes = response.data
          if (allRelatedNodes && allRelatedNodes.length) {
            var i = 0
            for (; allRelatedNodes[i]; ) {
              let _item = allRelatedNodes[i]
              if (_item.JSONData && _item.JSONData !== null) {
                let itemsList = JSON.parse(_item.JSONData)
                const propOwn = Object.getOwnPropertyNames(itemsList)
                if (propOwn.length > 0) {
                  let itemsObjects = Object.keys(itemsList).map(
                    key => itemsList[key]
                  )
                  _item.items = itemsObjects
                  _allData.push(_item)
                }
              }
              i++
            }
            setAllData(_allData)
          }
        })
        .catch(function (error) {})
    }
  }, [id])

  return (
    <>
    {allData.length > 0 && (
    <div className="pb-4">
      <div className="board-page-title small">
        <h3 className="product-name-title d-flex align-items-center">
          Related Products
        </h3>
      </div>
      {allData.map((item, idx) => (
        <RuleSwiperItemsList
          key={`related-${idx}`}
          title={item.name}
          titleClass={`${
            item.smallTitle == 1 ? "title-small" : "title-default"
          }`}
          navClass={item.productType}
          rule={item}
        />
      ))}
    </div>
    )}
    </>
  )
}

export default HomeDesignRelatedItems
